/*
 * @Descripttion: 组件公用的变量导出
 * @Author: 张明尧
 * @Date: 2021-02-22 15:46:28
 * @LastEditTime: 2021-03-17 16:15:33
 * 
 */
const product_hxmk_img = {
  hxmk1: require('@/assets/ktdn/cp/1.png'),
  hxmk2: require('@/assets/ktdn/cp/2.png'),
  hxmk3: require('@/assets/ktdn/cp/3.png'),
  hxmk4: require('@/assets/ktdn/cp/4.png'),
  hxmk5: require('@/assets/ktdn/cp/5.png'),
  hxmk6: require('@/assets/ktdn/cp/6.png'),
  hxmk7: require('@/assets/ktdn/cp/7.png'),
  hxmk8: require('@/assets/ktdn/cp/8.png'),
  hxmk9: require('@/assets/ktdn/cp/9.png'),
};

const Datas = {
  // 核心价值中的固定数据
  video_datas: [
    {
      img: '',
      show: false,
      text: {
        title: 'CRM、精准估算成交几率',
        mains: [
          {
            content: '第一步：制定销售行动方案',
          },
          {
            content: '第二步：编制行动评分标准、构建几率测评卡',
          },
          {
            content: '第三步：商机测评、得分上报',
          },
          {
            content: '第四步：预测成交趋势、辅助销售决策',
          }
        ]
      }
    },
    {
      img: '',
      show: false,
      text: {
        title: 'CRM、搞懂客户画像，摸清客户底牌',
        mains: [
          {
            content: '第一步：多渠道收集客户情报',
          },
          {
            content: '第二步：一图两卡帮你一网打尽一剑封喉',
          },
          {
            content: '第三步：标清干系人角色，搞清决策路线',
          },
          {
            content: '第四步：排除地雷、培养教练、激活欲望、刺激成交',
          },
        ]
      }
    },
    {
      img: '',
      show: false,
      text: {
        title: 'CRM、深度应用全员销售体系',
        mains: [
          {
            content: '第一步：构建销售四连环团队',
          },
          {
            content: '第二步：销售、主管、技术、服务环环相扣',
          },
          {
            content: '第三步：任务跟踪，事事落实',
          },
          {
            content: '第四步：奖励优秀，激发员工激情',
          },
        ]
      }
    },
    {
      img: '',
      show: false,
      text: {
        title: 'PM+CRM、让团队真正学会报价',
        mains: [
          {
            content: '第一步：精准估算项目成本',
          },
          {
            content: '第二步：利用报价工具预测经营目标',
          },
          {
            content: '第三步：流程审核，确保精准无误',
          },
          {
            content: '第四步：快速导出公司标准报价文件',
          },
        ]
      }
    },
    {
      img: '',
      show: false,
      text: {
        title: 'CRM、订单合同全生命周期管理',
        mains: [
          {
            content: '第一步：销售商机、订单合同、项目执行上下贯穿',
          },
          {
            content: '第二步：紧盯回款，逾期预警，进度监控',
          },
          {
            content: '第三步：合同变更，满足一切执行中的数据变化',
          },
        ]
      }
    },
    {
      img: '',
      show: false,
      text: {
        title: 'PM+OA、精准掌握员工的工作产能',
        mains: [
          {
            content: '第一步：事事有工单、拒绝口述任务',
          },
          {
            content: '第二步：员工工作台、每日任务一目了然',
          },
          {
            content: '第三步：工时监控、精确成本核算',
          },
          {
            content: '第四步：数据分析、辅助公司战略调整',
          },
        ]
      }
    },
    {
      img: '',
      show: false,
      text: {
        title: 'PM+OA、准确计算团队产能',
        mains: [
          {
            content: '第一步：管理工时、项目工时、无效工时分类统计',
          },
          {
            content: '第二步：工时、工单相互协同',
          },
          {
            content: '第三步：部门报表、项目报表、个人报表数实时呈现',
          },
          {
            content: '第四步：产能分析、直击弱点提高团队战斗力',
          }
        ]
      }
    },
    {
      img: '',
      show: false,
      text: {
        title: 'HR、人事、考勤、假期、薪酬游刃有余',
        mains: [
          {
            content: '第一步：年假、病假、福利假，发假、扣假、调休假',
          },
          {
            content: '第二步：入职、离职、岗位变、调岗、调薪、调职级',
          },
          {
            content: '第三步：外勤、内勤、做考勤、指纹、刷脸、APP',
          },
          {
            content: '第四步：福利、薪酬、算工资、公式、计算、省人工',
          },
        ]
      }
    },
    {
      img: '',
      show: false,
      text: {
        title: 'KPI、个性指标才是考核的灵魂',
        mains: [
          {
            content: '第一步：制定绩效考核计划',
          },
          {
            content: '第二步：编制通用指标与个性指标',
          },
          {
            content: '第三步：电脑与人工同考核，领导省心、员工舒心',
          },
          {
            content: '第四步：考核挂接薪酬，奖罚落到实处',
          },
        ]
      }
    },
    {
      img: '',
      show: false,
      text: {
        title: 'JEPaaS、低代码平台助力',
        mains: [
          {
            content: '第一步：建表格、画表单、挂菜单轻松搞定',
          },
          {
            content: '第二步：做流程、做报表、做你想做的功能',
          },
          {
            content: '第三步：微信、钉钉、飞书、WeLink任意对接',
          },
          {
            content: '第四步：IOS、Android  数据自由连通',
          },
        ]
      }
    },
  ],
  // 产品中的核心模块数据
  product_hxmk_datas: [
    {
      title: '蒜瓣营销（CRM）',
      img: product_hxmk_img.hxmk1,
      content: '全面的客户管理，加速商机转化特有的销售四连环，助您成交大客户',
    },
    {
      title: '蒜瓣办公（OA）',
      img: product_hxmk_img.hxmk1,
      content: '保障组织管理制度落地，提升执行力建立以目标为核心的工作执行体系',
    },
  ]
};

export default Datas;