<!--
 * @Descripttion: 
 * @Author: 张明尧
 * @Date: 2021-02-02 10:20:46
 * @LastEditTime: 2021-03-01 09:26:29
-->
<template>
  <div>
    <div class="home-top-main">
      <Top />
      <Video />
    </div>
  </div>
</template>

<script>
// banner图的组件
import Top from '@/pages/home/components/top'
// 视频组件
import Video from '@/pages/home/components/video'
export default {
  components: {
    Top,
    Video,
  },
  data() {
    return {

    }
  },
}
</script>
<style lang="scss" scoped>
  .home-top-main {
    
  }
</style>
