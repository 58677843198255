<!--
 * @Descripttion: 视频播放页面
 * @Author: 张明尧
 * @Date: 2021-02-22 15:05:05
 * @LastEditTime: 2021-03-18 18:06:40
-->
<template>
  <div class="video-main">
    <div class="title-text title-more-text">
      助您构建企业可持续性信息平台
      <span @click="openUrl('/product')" class="more-text">了解更多</span>
    </div>
    <div class="video-div-tab">
      <el-tabs v-model="activeVideoName" @tab-click="handleVideoTag">
        <el-tab-pane v-for="(vd_item, _vd_item) in videoTag" :key="`video_tag_${_vd_item}`" :label="vd_item.name" :name="vd_item.name">
          {{vd_item.name}}
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="video-div">
        <video-player 
        class="video-player vjs-custom-skin video-src"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
        @ended="onPlayerEnded($event)"
        ></video-player>
        <!-- <video style=" outline: none;" class="video-src" controls autoplay muted type="video/mp4"  src="../../../assets/ktdn/video/homeVideo.mp4"></video> -->
    </div>
    <div class="title-text margin-top-100">
      核心价值
    </div>
    <div class="video-hxjz">
      <div :class="_main % 2 == 0 && 'background-FBFBFC'" class="video-hxjz-div" v-for="(main, _main) in datas" :key="_main">
        <div :ref="`home_ref_${_main}`" class="width-1300">
          <el-row style="width: 100%; display: flex; align-items: center;">
            <el-col :span="12">
              <div v-if="_main % 2" >
                <div class="title-text" style="text-align: left">
                  <span>{{main.text.title}}</span>
                </div>
                <div class="margin-top-40">
                  <div class="title-text-details" v-for="(text, _text) in main.text.mains" :key="`${_text}text`">
                    <span>{{text.content}}</span>
                  </div>
                </div>
              </div>
              <img v-else class="img-hxjz" :src="main.img" alt="">
            </el-col>
            <el-col :class="_main % 2 && 'text-right'" :span="12">
              <img v-if="_main % 2" class="img-hxjz" :src="main.img" alt="">
              <div class="title-text-padding-left" v-else>
                <div class="title-text" style="text-align: left; color: #ffffff">
                  {{main.text.title}}
                </div>
                <div class="margin-top-40">
                  <div style="color: #ffffff" class="title-text-details_2" v-for="(text, _text) in main.text.mains" :key="`${_text}text`">
                    {{text.content}}
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Datas from './js/common'; // 公共组件的数据
const imgs = {
  hxjz1: require('../../../assets/ktdn/sy/1-1.svg'),
  hxjz2: require('../../../assets/ktdn/sy/2-2.svg'),
  hxjz3: require('../../../assets/ktdn/sy/3-3.svg'),
  hxjz4: require('../../../assets/ktdn/sy/4-4.svg'),
  hxjz5: require('../../../assets/ktdn/sy/5-5.svg'),
  hxjz6: require('../../../assets/ktdn/sy/6-6.svg'),
  hxjz7: require('../../../assets/ktdn/sy/7-7.svg'),
  hxjz8: require('../../../assets/ktdn/sy/8-8.svg'),
  hxjz9: require('../../../assets/ktdn/sy/9-9.svg'),
  hxjz10: require('../../../assets/ktdn/sy/10-10.svg'),
};
export default {
  data() {
    return {
      datas: Datas.video_datas,
      activeVideoName: '企业大脑',
      videoTag: [
        {
          type : "video/mp4",
          name: '企业大脑',
          src : require('../../../assets/ktdn/video/homeVideo.mp4')//url地址
        },
        {
          type : "video/mp4",
          name: '商机测评',
          src : require('../../../assets/ktdn/video/homeVideo2.mp4')//url地址
        },
        {
          type : "video/mp4",
          name: '工作台',
          src : require('../../../assets/ktdn/video/homeVideo3.mp4')//url地址
        },
        {
          type : "video/mp4",
          name: '销售展板',
          src : require('../../../assets/ktdn/video/homeVideo4.mp4')//url地址
        },
        {
          type : "video/mp4",
          name: '批注微邮',
          src : require('../../../assets/ktdn/video/homeVideo5.mp4')//url地址
        },
        {
          type : "video/mp4",
          name: '客户画像',
          src : require('../../../assets/ktdn/video/homeVideo6.mp4')//url地址
        },
        {
          type : "video/mp4",
          name: '数据标记',
          src : require('../../../assets/ktdn/video/homeVideo7.mp4')//url地址
        },
        {
          type : "video/mp4",
          name: '二次开发',
          src : require('../../../assets/ktdn/video/homeVideo8.mp4')//url地址
        },
      ],
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay : true, //如果true,浏览器准备好时开始回放。
        muted : true, // 默认情况下将会消除任何音频。
        loop : true, // 视频一结束就重新开始。
        preload : 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language : 'zh-CN',
        fluid : true,
        // aspectRatio: '16:9', 
        sources : [
          {
            type : "video/mp4",
            src : require('../../../assets/ktdn/video/homeVideo.mp4')//url地址
          },
        ],
        notSupportedMessage : '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar : {
          timeDivider : true,//当前时间和持续时间的分隔符
          durationDisplay : true,//显示持续时间
          remainingTimeDisplay : false,//是否显示剩余时间功能
          fullscreenToggle : true  //全屏按钮
        }
      },
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  created() {
    this.initImgs();
    this.playerMyVideo();
  },
  methods: {
    openUrl(url) {
      if(!url) return;
      this.$router.push({path: url})
    },
    openWindow(url) {
      if(!url) return;
      window.open(url);
    },
    // 点击切换视频
    handleVideoTag() {
      const arr = this.videoTag.filter(dd => dd.name == this.activeVideoName);
      this.playerOptions['sources'][0]['src'] = arr[0].src;
      this.playerMyVideo();
    },
    // 播放视频
    playerMyVideo() {
      const video = this.$refs.videoPlayer && this.$refs.videoPlayer.player;
      video && video.play(); // 播放视频
    },
    // 视频播放结束
    onPlayerEnded(e) {
      console.log(e);
    },
    // 初始化图片地址
    initImgs() {
      this.datas = this.datas.filter((res, index) => {
        res.img = imgs[`hxjz${index + 1}`]
        return res;
      })
    },
    // 监听当前的滚动条位置
    handleScroll() {
      this.datas.forEach((r, i) => {
        this.gdjz(`home_ref_${i}`, 20, () => {
          r.show = true;
        })
      })
    },
    // 判断是否到达指定位置
    gdjz (div, offset, callback) {
      let dom = this.$refs[div][0];
      if(dom){
        let a,b,c;
        a = dom.offsetTop + offset; // 元素距离相对父级的高度，这里父级指的是body
        b = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop //  获取窗口滚动条高度
        c = document.documentElement.clientHeight || document.body.clientHeight // 获取浏览器可视区的高度
        if( dom.offsetTop != 0 &&  b + c > a ) {
          dom.classList.add("slideUp");
          callback && callback()
        }
      }
    }
  }
}
</script>
<style lang="scss">
.video-main { 
  .el-tabs__item {
    font-size: 20px;
  }
  .el-tabs__nav-wrap::after {
    background-color: #f3f5f8;
  }
}
</style>
<style lang="scss" scoped>
  .video-main {
    background: #FFFFFF;
    padding-top: 100px;
    min-height: calc(100vh - 80px);
    .background-FBFBFC {
      background: #0D47A6;
    }
    .title-text-padding-left {
      padding-left: 30px;
    }
    .title-text {
      color: #3f3f3f;
      font-size: 35px;
      text-align: center;
      font-weight: 600;
    }
    .title-text-jepaasCom {
      text-align: center;
      margin-top: 20px;
      .jepaas-btn {
        font-size: 18px;
      }
    }
    .video-div-tab {
      width: 1400px;
      margin: 30px auto 0 auto;
      height: 80px;
      line-height: 80px;
      text-align: center;
      .video-div-tab-tag {
        padding: 10px 20px;
        height: 60px;
        font-size: 18px;
        margin-left: 20px;
      }
    }
    .title-more-text {
      width: 1400px;
      margin: 0 auto;
      position: relative;
    }
    .more-text {
      // float: right;
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: 20px;
      color: #0358FD;
      text-align: right;
      font-weight: 400;
      cursor: pointer;
    }
    .video-hxjz {
      margin-top: 100px;
      .video-hxjz-div {
        padding: 150px 0;
        .width-1300 {
          width: 1400px;
          margin: 0 auto;
        }
        .img-hxjz {
          width: 680px;
          height: auto;
        }
        .title-text-details {
          font-size: 20px;
          color: #3f3f3f;
          font-weight: 500;
          line-height: 60px;
          position: relative;
          padding-left: 20px;
          &::before {
            content: ' ';
            position: absolute;
            left: 5px;
            top: 30px;
            margin-top: -2.5px;
            width: 5px;
            height: 5px;
            background-color: #3f3f3f;
            border-radius: 50%;
          }
        }
        .title-text-details_2 {
          font-size: 20px;
          color: #3f3f3f;
          font-weight: 500;
          line-height: 60px;
          position: relative;
          padding-left: 20px;
          &::before {
            content: ' ';
            position: absolute;
            left: 5px;
            top: 30px;
            margin-top: -2.5px;
            width: 5px;
            height: 5px;
            background-color: #ffffff;
            border-radius: 50%;
          }
        }
      }
    }
    .video-div {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      border-radius: 10px;
      .video-src {
        width: 1400px;
        height: auto;
        margin: 0 auto;
        border-radius: 10px;
        // opacity: 0.69;
        // background: #141414;
      }
    }
  }
</style>