<!--
 * @Descripttion: 
 * @Author: 张明尧
 * @Date: 2021-02-22 11:28:09
 * @LastEditTime: 2021-03-18 16:01:41
-->
<template>
  <div class="home-top-banner">
    <div class="left-banner">
      <div class="textBig">
        企业大脑3.0
      </div>
      <div class="textBig">
        您的一站式管理加速器
      </div>
      <div class="textSmall margin-top-10">
        <div>七大核心系统：营销（CRM）、办公（OA）、项目（PM）、人力（HR）</div>
        <div>知识（KMS）、文化（EC）、绩效（KPI）</div>
      </div>
      <div class="margin-top-40">
        <el-button @click="openUrl('/product')" class="banner-btnClass details-btn" type="primary">产品详情</el-button>
        <el-button @click="openWindow('http://www.jepaas.com')" class="banner-btnClass buy-btn" plain>基于JEPaaS搭建</el-button>
      </div>
      <!-- <div class="margin-top-40">
        <el-button @click="openWindow('http://www.jepaas.com')" class="banner-btnClassJepaas buy-btn jeaas-btn" plain>
          基于JEPaaS低代码平台搭建
        </el-button>
      </div> -->
    </div>
    <div class="right-banner">
      <img class="banner-img" src="../../../assets/ktdn/banner.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  methods: {
    openUrl(url) {
      if(!url) return;
      this.$router.push({path: url})
    },
    openWindow(url) {
      if(!url) return;
      window.open(url);
    },
  }
}
</script>
<style lang="scss">
.home-top-banner {
  .details-btn {
    width: 220px;
    background: #0358FD;
    border-color: #0358FD;
  }
  .buy-btn {
    height: 58px;
    width: 260px;
    font-size: 20px;
    margin-left: 0;
    margin-left: 30px;
    color: #0358FD;
    border-color: #0358FD;
  }
  .banner-btnClass{
    height: 58px;
    border-radius: 6px;
    font-size: 20px;
    font-weight: 400;
  }
  .banner-btnClassJepaas {
    height: 58px;
    width: 470px;
    font-size: 20px;
    margin-left: 0;
  }
  .jeaas-btn {
    background: #f15442;
    color: #fff;
    transition: background .7s;
    border-color: #f15442;
  }
  .jeaas-btn:hover {
    background: rgba(241,84,66, .8);
    color: #fff;
    border-color: #f15442;
  }
}
</style>
<style lang="scss" scoped>
.home-top-banner {
  min-height: calc(100vh - 80px);
  background-image: url('../../../assets/ktdn/home-banner.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left-banner {
    padding-left: 120px;
    .textBig {
      font-size: 55px;
      color: #3f3f3f;
      font-weight: 600;
    }
    .textSmall {
      color: #3f3f3f;
      font-size: 20px;
      line-height: 1.5;
    }
  }
  .right-banner {
    margin-right: 100px;
    .banner-img {
      width: 800px;
      height: auto;
    }
  }
  .herf-btn {
    width: 250px;
    height: 42px;
    background: linear-gradient(247deg, #F8FBFF 0%, #E5F0FE 100%);
    border-radius: 25px;
    color: #0358FD;
    display: flex;
    align-items: center;
    padding: 0 20px;
    cursor: pointer;
  }
}
</style>